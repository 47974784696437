import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import SupporterCategory from "../components/SupporterList/SupporterCategory"
import tw from "twin.macro"
import aboutBg from "../images/about-bg.svg"
import { Basketball } from "../components/Icons"

const ViewAthletes = () => {
    const [athletes, setAthletes] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchAthletes = async () => {
      try {
        const res = await fetch(
          `${process.env.GATSBY_API_URL}/athletes`,
          {
            headers: {
              "x-api-key": process.env.GATSBY_API_KEY,
            },
          }
        )
        const json = await res.json()

        if ("error" in json) {
          throw new Error(json.error)
        }

        if (json?.length > 0) {
          let names = Array.from(new Set(json?.map(record => record?.name)))
          setAthletes(names)
        } else {
          setError("No athletes found.")
        }
      } catch (error) {
        setError(error.message)
      }
      setLoading(false)
    }
    fetchAthletes()
  }, [])

  return (
    <Layout>
      <section
        tw="bg-gray-mid relative"
        css={[
          tw`bg-cover bg-center min-h-[80vh]`,
          `background-image: url(${aboutBg}); overflow-x: hidden; overflow-x: clip;`,
        ]}
      >
        <h2 css={[tw`text-center pt-12 text-white tracking-wide`]}>
          Athletes
        </h2>
        {error && (
          <div
            css={[
              tw`max-w-5xl mx-auto mt-4 p-4 border border-primaryDark text-white bg-primary font-bold`,
            ]}
          >
            {error}
          </div>
        )}
        {loading ? (
          <div
            css={[
              tw`p-4 text-center uppercase font-bold italic text-2xl text-gray-dark`,
            ]}
          >
            Loading{" "}
            <Basketball css={[tw`h-4 -mt-1 inline-block animate-spin`]} />
          </div>
        ) : (
          <SupporterCategory
            listings={athletes}
          />
        )}
      </section>
    </Layout>
  )
}

export default ViewAthletes
