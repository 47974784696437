import React from "react"
import SupporterListing from "./SupporterListing"
import tw from "twin.macro"

// Also used for View Athletes page
const SupporterCategory = ({ title, listings }) => {
  const sortedListings = listings?.sort((a, b) => a.localeCompare(b, 'en-US', {caseFirst: 'upper'}));
  return (
    <article
      css={[ 
        tw`p-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-w-5xl mx-auto`,
      ]}
    >
      {title && (
        <h3 css={[tw`bg-primary text-white p-3 col-span-full rounded-sm px-4`]}>
          {title}
        </h3>
      )}
      {sortedListings &&
        sortedListings
        .map((item, index) => (
          <SupporterListing key={index} listing={item} styles={tw`p-3`} />
        ))}
    </article>
  )
}

export default SupporterCategory
