import React from 'react'
import tw from 'twin.macro'

// Also used for View Athletes 
const SupporterListing = ({listing, styles}) => {
    return (
        <div css={[tw`bg-[rgba(255,255,255,0.7)] rounded-sm`, styles]}>
            <p css={[tw`font-sans font-semibold text-center capitalize`]}>{listing}</p>
        </div>
    )
}

export default SupporterListing